.repo-item {
  min-width: 200px;
  border-radius: 4px;
  margin: 5px;
  overflow: hidden;
  border: 1px solid #DDD;
  box-shadow: 0 0 10px #EEE;
  background: #FFF;
}

.repo-item .org {
  text-align: center;
  padding: 2px;
  display: block;
  font-size: 0.6em;
  color: #6C6C6C;
}

.repo-item .repo {
  background: #EDF7FA;
  text-align: center;
  padding: 2px;
  display: block;
}

.repo-item .issue-pull-request-count {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;
}
