.pull-request-item {
  margin: 5px;
  border: 1px solid #EEE;
  padding: 5px 10px;
  background: #FFF;
  box-shadow: 0 0 10px #EEE;
  border-radius: 4px;
}

.pull-request-item .repo {
  font-size: 0.7em;
  color: #6C6C6C;
}

.pull-request-item .content {
  margin-top: 3px;
  display: flex;
  flex-direction: row;
}

.pull-request-item .content .identifier {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pull-request-item .content .identifier .avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
}

.pull-request-item .content .identifier .number {
  color: #6C6C6C;
  margin-right: 5px;
}

.pull-request-item .content .identifier .title {
  margin-right: 5px;
}

.pull-request-item .created-at {
  font-size: 0.7em;
  color: #6C6C6C;
  margin-top: 3px;
}
