.issue-list > .title {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.issue-list .title h2 {
  margin: 0;
  font-weight: 100;
}

.issue-list .title .count {
  margin: 0 0 3px 0;
  font-weight: 100;
  position: absolute;
  right: 25px;
}

.issue-list .loading {
  width: 100%;
  text-align: center;
  color: #ADADAD;
}
