body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: #F9F9F9;
}

label {
  margin: 5px;
}

input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=text],
input[type=url],
textarea{
  appearance: none;
  display: inline-block;
  min-height: 36px;
  padding: 8px 12px;
  width: 300px;
  margin-top: 5px;
  background: #fff;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  font-size: 15px;
  color: #404040;
  outline: none;
  margin: 5px;
}

input[type='submit'], button, .btn{
  color: #000;
  text-decoration: none;
  border: 0px;
  outline: none;
  display: block;
  padding: 8px 22px;
  margin-bottom: 0;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: .12rem;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  margin: 5px;
}

input[type='submit']:hover, button:hover, .btn:hover{
 background-color: rgba(0,0,0, .3);  /* change button color here */
}

input[type='submit']:active, button:active, .btn:active{
  background-color: rgba(0,0,0, .3);  /* change button color here */
  border: 0px;
  -webkit-box-shadow: inset 0px 2px 2px 2px rgba(0, 0, 0, .15);
  box-shadow: inset 0px 2px 2px 2px rgba(0, 0, 0, .15);
}

a, a:visited {
  color: #555;
  text-decoration: none;
}

a:hover, a:focus {
  color: #222;
}

a:active {
  color: #000;
}
